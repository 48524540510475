html,
body {
  min-width: 375px;
  padding: 0;
  margin: 0;
}
a {
  color: inherit;
}
.MuiInputBase-inputMultiline {
  box-sizing: content-box;
}
* {
  box-sizing: border-box;
}
a:hover {
  filter: brightness(120%);
}

a:active {
  filter: brightness(80%);
}
.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}

.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

fortnite-navigation {
  --color-text-on-accent: #fff;
  /*CTA button colors*/
  --color-fill-primary-default: #038cdf;
  --color-fill-primary-hover: #04a7ff;
  --color-fill-secondary-default: #038cdf;
  --color-fill-secondary-hover: #04a7ff;

  /*header background*/
  --effect-material-blur: none;
  --color-background-transparent-default: #18181c;
  --color-background-transparent-menu: #303034;
}
