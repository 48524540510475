@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('work-sans/worksans-400.woff2') format('woff2');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src:
    local(''),
    url('work-sans/worksans-500.woff2') format('woff2');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src:
    local(''),
    url('work-sans/worksans-600.woff2') format('woff2');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('work-sans/worksans-700.woff2') format('woff2');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 800;
  src:
    local(''),
    url('work-sans/worksans-800.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('inter/inter-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src:
    local(''),
    url('inter/inter-500.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src:
    local(''),
    url('inter/inter-600.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src:
    local(''),
    url('inter/inter-800.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('inter/inter-700.woff2') format('woff2');
}

@font-face {
  font-family: 'Burbank Small';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('burbank/burbank-small-700.woff2') format('woff2');
}
